import { t } from "@lingui/macro";
import { WorkspaceSettingsQuery } from "@src/__generated__/graphql";
import {
  Accounting_Account_Type,
  AccountingAccountBudgetCategory,
  BudgetItemTypeEnum,
  Currency,
  DefaultCurrency,
  InputAccountingAccount,
  Maybe,
  VatRate,
  WorkspaceAccountingAccount,
  WorkspaceAccountingAccountSettings,
  WorkspaceFinancialSetting,
} from "@src/__generated__/urql-graphql";
import { IOption } from "@src/components/ui-kit";
import { nominate } from "@src/utils/formatters";
import { TToFieldStates } from "@src/utils/forms/ts-utils";
import {
  onlyNumericWithoutDecimal,
  required,
} from "@src/utils/forms/validators";
import { FieldState, FormState } from "formstate";
import { compact } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { v4 as uuidV4 } from "uuid";

export const EXCHANGE_RATE_DENOMINATION = 10;

type VatRateStateProps = Omit<VatRate, "amount" | "deleted" | "id" | "type"> & {
  amount: string;
};
class VatRateState extends FormState<TToFieldStates<VatRateStateProps>> {
  id?: VatRate["id"];
  internalId: string;
  constructor(
    id?: VatRate["id"],
    fields: VatRateStateProps = {
      amount: "",
      default: false,
    },
  ) {
    super({
      amount: new FieldState(String(fields.amount)).validators(
        required,
        onlyNumericWithoutDecimal,
      ),
      default: new FieldState(fields.default),
    });
    this.id = id;
    this.internalId = uuidV4();
  }
}

type CurrencyStateProps = Omit<
  Currency,
  "id" | "exchange_rate" | "denomination"
> & {
  isAuto: boolean;
  exchange_rate: string;
};
class CurrencyState extends FormState<TToFieldStates<CurrencyStateProps>> {
  id?: Currency["id"];
  internalId: string;

  constructor(
    id?: Currency["id"],
    fields: Omit<CurrencyStateProps, "isAuto"> = {
      code: "",
      exchange_rate: "1",
    },
  ) {
    super({
      code: new FieldState(fields.code).validators(required),
      exchange_rate: new FieldState(fields.exchange_rate),
      isAuto: new FieldState(fields.exchange_rate === ""),
    });
    this.id = id;
    this.internalId = uuidV4();
  }

  get canBeRemoved() {
    return !Boolean(this.id);
  }

  get isExchangeRateInputDisabled() {
    return this.$.isAuto.$;
  }

  handleSetAuto(isAuto: boolean) {
    this.$.isAuto.onChange(isAuto);
    if (isAuto) {
      this.$.exchange_rate.onChange("");
    } else {
      this.$.exchange_rate.onChange("1");
    }
  }
}

type AccountingAccountStateProps = Omit<
  WorkspaceAccountingAccount,
  "id" | "accounting_type" | "budget_categories"
>;
class AccountingAccountState extends FormState<
  TToFieldStates<
    AccountingAccountStateProps & {
      budget_categories: string[];
    }
  >
> {
  state: FinancialSettingsState;
  id?: WorkspaceAccountingAccount["id"];
  accountingType: Accounting_Account_Type;
  internalId: string;

  constructor(
    state: FinancialSettingsState,
    accountingType: Accounting_Account_Type,
    id?: WorkspaceAccountingAccount["id"],
    fields: AccountingAccountStateProps = {
      account_number: "",
      title: "",
    },
    budgetCategories: AccountingAccountBudgetCategory[] = [],
  ) {
    super({
      account_number: new FieldState(fields.account_number).validators(
        required,
        (value) =>
          !/^[\d ]+$/.test(value) && t`Only numbers and spaces are allowed`,
        (value) =>
          this.state.form.$.billingAccountingAccounts.$.find(
            (a) => a.$.account_number.$ === value && a !== this,
          ) && t`Account number must be unique`,
      ),
      title: new FieldState(fields.title).validators(required),
      budget_categories: new FieldState(
        budgetCategories.map(
          ({ budget_category_id, budget_category_type }) =>
            budget_category_id +
            BUDGET_CATEGORY_VALUE_SEPARATOR +
            budget_category_type,
        ),
      ),
    });
    makeObservable(this, {
      mutationInput: computed,
    });
    this.state = state;
    this.id = id;
    this.internalId = uuidV4();
    this.accountingType = accountingType;
  }

  get mutationInput(): InputAccountingAccount {
    return {
      id: this.id,
      account_number: this.$.account_number.$,
      title: this.$.title.$,
      accounting_type: this.accountingType,
      budget_categories: compact(
        this.$.budget_categories.$.map((category) => {
          const [id, type] = category.split(BUDGET_CATEGORY_VALUE_SEPARATOR);
          if (!id || !type) return;
          return {
            budget_category_id: id,
            budget_category_type: type,
          };
        }),
      ),
    };
  }
}

const BUDGET_CATEGORY_VALUE_SEPARATOR = "-";
export class FinancialSettingsState {
  @observable vatRateToDelete: VatRateState | undefined = undefined;
  @observable currencyOptions: IOption[] = [];
  budgetCategoryOptions: IOption[] = [];
  @observable billingAccountingAccountToDelete:
    | AccountingAccountState
    | undefined = undefined;
  @observable expensesAccountingAccountToDelete:
    | AccountingAccountState
    | undefined = undefined;
  readonly form = new FormState({
    vatRates: new FormState<Array<VatRateState>>([]),
    billingAccountingAccounts: new FormState<Array<AccountingAccountState>>([]),
    expensesAccountingAccounts: new FormState<Array<AccountingAccountState>>(
      [],
    ),
    currencies: new FormState<Array<CurrencyState>>([]),
    showUnitPrice: new FieldState(false),
    showBillingAccounting: new FieldState(false),
    show_full_bank_account_information: new FieldState(false),
  });

  constructor() {
    makeObservable(this);
  }

  init(
    financialSettings: WorkspaceFinancialSetting,
    accountingAccountSettings: WorkspaceAccountingAccountSettings,
    ourWorkBudgetCategories: NonNullable<
      WorkspaceSettingsQuery["ourWorkBudgetCategories"]
    >,
    expenseBudgetCategories: NonNullable<
      WorkspaceSettingsQuery["expenseBudgetCategories"]
    >,
    defaultCurrencies: Maybe<DefaultCurrency>[],
  ) {
    this.form.$.vatRates = new FormState(
      financialSettings.vat_rates
        .filter((vatRate) => !vatRate.deleted)
        .map((vatRate) => {
          const humanReadableVatRate = {
            ...vatRate,
            amount: String(vatRate.amount / 100),
          };
          return new VatRateState(vatRate.id, humanReadableVatRate);
        }),
    );
    this.currencyOptions = compact(
      defaultCurrencies.map((currency) => {
        return currency
          ? {
              value: currency.code,
              label: currency.code,
            }
          : null;
      }),
    );

    this.budgetCategoryOptions = [];
    for (const category of ourWorkBudgetCategories) {
      this.budgetCategoryOptions.push({
        value:
          category.id +
          BUDGET_CATEGORY_VALUE_SEPARATOR +
          BudgetItemTypeEnum.OurWorkBudgetItem,
        label: category.name,
      });
    }

    for (const category of expenseBudgetCategories) {
      this.budgetCategoryOptions.push({
        value:
          category.id +
          BUDGET_CATEGORY_VALUE_SEPARATOR +
          BudgetItemTypeEnum.ExpenseBudgetItem,
        label: category.name,
      });
    }

    this.form.$.currencies = new FormState(
      financialSettings.currencies.map(
        (currency) =>
          new CurrencyState(currency.id, {
            code: currency.code,
            exchange_rate: currency.exchange_rate
              ? nominate(
                  currency.exchange_rate,
                  EXCHANGE_RATE_DENOMINATION,
                ).toString()
              : "",
          }),
      ),
    );
    this.form.$.billingAccountingAccounts = new FormState(
      accountingAccountSettings.accounting_accounts
        .filter(
          (account) =>
            account.accounting_type === Accounting_Account_Type.Invoice,
        )
        .map(
          (account) =>
            new AccountingAccountState(
              this,
              account.accounting_type,
              account.id,
              account,
              account.budget_categories ?? [],
            ),
        ),
    );
    this.form.$.expensesAccountingAccounts = new FormState(
      accountingAccountSettings.accounting_accounts
        .filter(
          (account) =>
            account.accounting_type === Accounting_Account_Type.Expense,
        )
        .map(
          (account) =>
            new AccountingAccountState(
              this,
              account.accounting_type,
              account.id,
              account,
            ),
        ),
    );

    this.form.$.showUnitPrice = new FieldState(
      financialSettings.default_show_unit_price_on_invoices,
    );
    this.form.$.showBillingAccounting = new FieldState(
      financialSettings.default_show_billing_accounting_on_invoices,
    );
  }

  @computed get nonSelectedCurrencyOptions(): IOption[] {
    return this.currencyOptions.filter(({ value }) =>
      this.form.$.currencies.$.every((currency) => currency.$.code.$ !== value),
    );
  }

  addNewVatRate() {
    const newLength = this.form.$.vatRates.$.push(new VatRateState());

    // If we just added our very first VAT rate, then no VAT rate is default currently.
    // We need to make the new one default.
    if (newLength === 1) {
      this.form.$.vatRates.$[0].$.default.onChange(true);
    }
  }

  addNewCurrency() {
    this.form.$.currencies.$.push(new CurrencyState());
  }

  @action.bound removeCurrency(idToRemove: string) {
    this.form.$.currencies = new FormState(
      this.form.$.currencies.$.filter(
        (currency) => currency.internalId !== idToRemove,
      ),
    );
  }

  @action.bound requestVatRateRemoval(vatRate: VatRateState) {
    this.vatRateToDelete = vatRate;
  }

  @action.bound removeVatRate(vatRate: VatRateState) {
    // Make sure to reassign default flag to another VAT rate if the one being deleted was default.
    if (vatRate.$.default.$) {
      this.form.$.vatRates.$.find((v) => v !== vatRate)?.$.default.onChange(
        true,
      );
    }

    this.form.$.vatRates = new FormState(
      this.form.$.vatRates.$.filter((v) => v !== vatRate),
    );

    this.vatRateToDelete = undefined;
  }

  makeDefault(vatRate: VatRateState) {
    this.form.$.vatRates.$.forEach((v) => {
      v.$.default.onChange(v === vatRate);
    });
  }

  addNewBillingAccountingAccount() {
    this.form.$.billingAccountingAccounts.$.push(
      new AccountingAccountState(this, Accounting_Account_Type.Invoice),
    );
  }

  addNewExpensesAccountingAccount() {
    this.form.$.expensesAccountingAccounts.$.push(
      new AccountingAccountState(this, Accounting_Account_Type.Expense),
    );
  }

  @action.bound requestBillingAccountingAccountRemoval(
    account: AccountingAccountState,
  ) {
    this.billingAccountingAccountToDelete = account;
  }

  @action.bound removeBillingAccountingAccount(
    account: AccountingAccountState,
  ) {
    this.form.$.billingAccountingAccounts = new FormState(
      this.form.$.billingAccountingAccounts.$.filter((a) => a !== account),
    );

    this.billingAccountingAccountToDelete = undefined;
  }

  @action.bound requestExpensesAccountingAccountRemoval(
    account: AccountingAccountState,
  ) {
    this.expensesAccountingAccountToDelete = account;
  }

  @action.bound removeExpensesAccountingAccount(
    account: AccountingAccountState,
  ) {
    this.form.$.expensesAccountingAccounts = new FormState(
      this.form.$.expensesAccountingAccounts.$.filter((a) => a !== account),
    );

    this.expensesAccountingAccountToDelete = undefined;
  }

  getFilteredCurrencyOptions(codeToFilterOut: Currency["code"]): IOption[] {
    return [
      { value: codeToFilterOut, label: codeToFilterOut },
      ...this.nonSelectedCurrencyOptions,
    ];
  }
}
