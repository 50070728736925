import {
  InputUpdateWorkspacePipedriveIntegration,
  Maybe,
  WorkspacePipedriveSettings,
} from "@src/__generated__/urql-graphql";
import { TToFieldStates } from "@src/utils/forms/ts-utils";
import { required } from "@src/utils/forms/validators";
import { FieldState, FormState } from "formstate";
import { action, makeObservable } from "mobx";

type PipedriveSettingsFormStateArgs = {
  pipedrive_stage_id:
    | NonNullable<WorkspacePipedriveSettings["pipedrive_stage_id"]>
    | undefined;
  project_stage_id:
    | NonNullable<WorkspacePipedriveSettings["project_stage_id"]>
    | undefined;
  project_billable: "true" | "false";
};
export class PipedriveSettingsState extends FormState<
  TToFieldStates<PipedriveSettingsFormStateArgs>
> {
  constructor() {
    super({
      pipedrive_stage_id: new FieldState<
        PipedriveSettingsFormStateArgs["pipedrive_stage_id"]
      >(undefined),
      project_stage_id: new FieldState<
        PipedriveSettingsFormStateArgs["project_stage_id"]
      >(undefined),
      project_billable: new FieldState<
        PipedriveSettingsFormStateArgs["project_billable"]
      >("false"),
    });
    makeObservable(this, {
      init: action.bound,
    });
  }

  init(settings?: Maybe<WorkspacePipedriveSettings>) {
    this.$.pipedrive_stage_id = new FieldState(
      settings?.pipedrive_stage_id ?? undefined,
    ).validators(required);
    this.$.project_stage_id = new FieldState(
      settings?.project_stage_id ?? undefined,
    ).validators(required);
    this.$.project_billable = new FieldState(
      settings?.project_billable ? "true" : "false",
    );
  }

  async updateInput(): Promise<
    InputUpdateWorkspacePipedriveIntegration | undefined
  > {
    const { hasError } = await this.validate();
    return hasError
      ? undefined
      : {
          settings: {
            pipedrive_stage_id: this.$.pipedrive_stage_id.$ ?? "",
            project_stage_id: this.$.project_stage_id.$ ?? "",
            project_billable: this.$.project_billable.$ === "true",
          },
        };
  }
}
